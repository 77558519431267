import { Divider, Typography } from 'antd';
import React, { useState } from 'react'
import { MdOutlineGroups } from 'react-icons/md';
import CustomModal from '../../../Components/CustomModal/CustomModal';
import CustomButton from '../../../Components/CustomButton';
import { HiOutlineUsers } from 'react-icons/hi2';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { useIntl } from 'react-intl';
import AddGroup from './AddGroup';

const { Title } = Typography
function GroupSection({ groups, getCustomers }) {
    const intl = useIntl()
    const [showAddGroup, setShowAddGroup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState(null)
    return (
        <div style={{
            marginTop: "30px",
            marginBottom: 20,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "8px"
        }}>
            <Divider plain style={{ margin: 0 }} orientation="left" >
                <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    <MdOutlineGroups size={22} />
                    <Title level={5} style={{ margin: 0 }}>{intl.formatMessage({ id: "customerGroups" })}</Title>
                </div>
            </Divider>
            <CustomModal
                title={intl.formatMessage({ id: selectedGroup ? "editGroup" : "addGroup" })}
                body={<AddGroup
                    selectedGroup={selectedGroup}
                    showAddGroup={showAddGroup}
                    setShowAddGroup={setShowAddGroup}
                    getCustomers={getCustomers}
                />}
                open={showAddGroup}
                setOpen={setShowAddGroup}
                showButton={false}
                showFooter={false}
            />
            <div style={{ display: "flex", alignItems: "center", gap: "8px", flexWrap: "wrap" }}>
                {groups.map((group, index) =>
                    <CustomButton
                        key={index}
                        text={group.name}
                        onClick={() => { setSelectedGroup(group); setShowAddGroup(true) }}
                        className={`btnRegister btn-text`}
                        style={{ padding: "25px" }}
                        icon={<HiOutlineUsers size={18} />}
                    />
                )}
                <CustomButton
                    text={intl.formatMessage({ id: "addGroup" })}
                    onClick={() => { setSelectedGroup(null); setShowAddGroup(true) }}
                    className={`btnRegister btn-text`}
                    type="primary"
                    style={{ padding: "25px" }}
                    icon={<AiOutlineUsergroupAdd size={18} />}
                />
            </div>
        </div>
    )
}

export default GroupSection