import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import openNotification from "../../../Components/Notifications";
import { Divider, Form, Input, Select, Typography } from "antd";
import CustomButton from "../../../Components/CustomButton";
import { useSelector } from "react-redux";
import { editCustomerById } from "../../../API/fetch";

const { Title } = Typography;
const { Option } = Select;
function CustomerEdit({ selectedCustomer, setShowEditCustomer, getCustomers }) {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const { tags } = useSelector((state) => state.store);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      await editCustomerById(selectedCustomer._id, values);

      setLoading(false);
      openNotification({
        title: "Success",
        description: intl.formatMessage({ id: "customerUpdated" }),
        type: "success",
        rtl: true,
      });
      setShowEditCustomer(false);
      await getCustomers();
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };

  useEffect(() => {
    if (selectedCustomer) {
      form.resetFields();
      form.setFieldsValue({
        name: selectedCustomer.name,
        email: selectedCustomer.email,
        phone: selectedCustomer.phone,
        tag1: selectedCustomer.tag1 && selectedCustomer.tag1._id ? selectedCustomer.tag1._id : "",
        tag2: selectedCustomer.tag2 && selectedCustomer.tag2._id ? selectedCustomer.tag2._id : "",
        tag3: selectedCustomer.tag3 && selectedCustomer.tag3._id ? selectedCustomer.tag3._id : "",
      });
    }
  }, [selectedCustomer, form]);


  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="update-customer"
        onFinish={onFinish}
      >
        <Form.Item label={intl.formatMessage({ id: "justName" })} name="name">
          <Input />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "email" })} name="email">
          <Input />
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "phone" })} name="phone">
          <Input />
        </Form.Item>

        <Divider plain style={{ margin: 0 }}>
          <Title level={5}>{intl.formatMessage({ id: "tagsSetting" })}</Title>
        </Divider>
        <Form.Item label={intl.formatMessage({ id: "tag1" })} name="tag1">
          <Select className="my-font">
            <Option value="" className="my-font" >
              {intl.formatMessage({ id: "noTag" })}
            </Option>
            {tags.tags1.map((tag, index) => (
              <Option value={tag._id} className="my-font" key={index}>
                {tag.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "tag2" })} name="tag2">
          <Select className="my-font">
            <Option value="" className="my-font" >
              {intl.formatMessage({ id: "noTag" })}
            </Option>
            {tags.tags2.map((tag, index) => (
              <Option value={tag._id} className="my-font" key={index}>
                {tag.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: "tag3" })} name="tag3">
          <Select className="my-font">
            <Option value="" className="my-font" >
              {intl.formatMessage({ id: "noTag" })}
            </Option>
            {tags.tags3.map((tag, index) => (
              <Option value={tag._id} className="my-font" key={index}>
                {tag.title}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px" }}
        >
          <CustomButton
            style={{ marginTop: "20px" }}
            text={intl.formatMessage({ id: "cancel" })}
            loading={loading}
            onClick={() => setShowEditCustomer(false)}
          />
          <CustomButton
            style={{ marginTop: "20px" }}
            text={intl.formatMessage({ id: "update" })}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
}

export default CustomerEdit;
