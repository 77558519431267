import axios from "axios";
import { api } from "./index";

export const signin = (data) => {
  return api.post(`/sign-in`, data);
};

export const postLogOut = () => {
  return api({
    method: "DELETE",
    url: `/sign-out`,
  });
};

export const getEvents = () => {
  return api.get(`/events`);
};

export const setEmailToCustomer = (data) => {
  return api.post(`/send-email`, data);
};

export const getUserData = () => {
  return api.get(`/user`);
};

export const editSetting = (id, data) => {
  return api.patch(`/stores/${id}`, data);
};

export const getTrackAnalytics = (start, end) => {
  return api.get(`/tracks/analytics?start=${start}&end=${end}`);
};

export const getProductAnalytics = (start, end) => {
  return api.get(`/products/analytics?start=${start}&end=${end}`);
};

export const editPassword = (data) => {
  return api.patch(`/change-password`, data);
};

//admin
export const getStores = () => {
  return api.get(`/stores`);
};

export const getStoreAdmin = (id) => {
  return api.get(`/stores/${id}`);
};

export const unsubscribeCustomer = (id, data) => {
  return api.patch(`/customers/${id}`, data);
};

export const getCampaignsDetails = () => {
  return api.get(`/campaigns`);
};

export const getRandomData = () => {
  return api.get(`/random-data`);
};
export const sendEmailTest = (data) => {
  return api.post(`/send-email-test`, data);
};
export const getTemplates = () => {
  return api.get(`/templates`);
};

// export const getTemplateAdmin = (id) => {
//   return api.get(`/templates/${id}`);
// };

// export const editTemplate = (id, data) => {
//   return api.patch(`/templates/${id}`, data);
// };

export const createTemplate = (data) => {
  return api.post(`/templates`, data);
};

export const getStoreTemplate = () => {
  return api.get(`/store-templates`);
};

export const templateDelete = (id) => {
  return api.delete(`/delete-template/${id}`);
};

export const createCampaign = (data) => {
  return api.post(`/campaigns`, data);
};

export const getStoreCampaigns = () => {
  return api.get(`/store-campaigns`);
};

export const getStoreCampaignStats = (id) => {
  return api.get(`/store-campaigns/${id}`);
};

export const editCampaign = (id, data) => {
  return api.patch(`/campaigns/${id}`, data);
};

export const cancelCampaign = (id) => {
  return api.delete(`/campaigns/${id}`);
};

export const editStoreTemplate = (id, data) => {
  return api.patch(`/store-templates/${id}`, data);
};

export const getStoreCustomers = (page, length, query) => {
  return api.get(`/store-customers?page=${page}&length=${length}&${query}`);
};

export const exportCustomerToExcel = () => {
  return api.get(`/customers-to-excel`);
};

export const importCustomerFromExcel = (data) => {
  return api.post(`/customers-from-excel`, data);
};

export const editCustomerById = (id, data) => {
  return api.put(`/customer/${id}`, data);
};

export const sendTestEmail = (data) => {
  return api.post(`/send-store-email-test`, data);
};

export const getStoreGroups = () => {
  return api.get(`/store-groups`);
};

export const addNewGroup = (data) => {
  return api.post(`/group`, data)
}

export const EditGroup = (data, id) => {
  return api.put(`/group/${id}`, data)
}

export const DeleteGroupByid = (id) => {
  return api.delete(`/group/${id}`)
}

export const createAdminTemplate = (data) => {
  return api.post(`/template-admin`, data);
};

export const editAdminTemplate = (id, data) => {
  return api.patch(`/template-admin/${id}`, data);
};

export const updateStoreAdmin = (id, data) => {
  return api.patch(`/admin-stores/${id}`, data);
};

export const getEmailAnalytics = (start, end) => {
  return api.get(`/email-analytics?startDate=${start}&endDate=${end}`);
};

export const getAllProducts = () => {
  return api.get(`/products`);
};

export const sendTestTemplateEmail = (id) => {
  return api.post(`/send-test-email/${id}`);
};

export const getTemplateLibrary = () => {
  return api.get(`/template-library`);
};

export const getAllMedias = () => {
  return api.get(`/medias`);
};

export const deleteMedia = (id) => {
  return api.delete(`/medias/${id}`);
};

export const getAllAdminTemplates = () => {
  return api.get(`/admin-templates`);
};

export const updateDefaultTemplate = (data) => {
  return api.patch(`/default-templates`, data);
};

export const createTag = (data) => {
  return api.post(`/tag`, data);
};

export const editTag = (id, data) => {
  return api.patch(`/tag/${id}`, data);
};

export const deleteTag = (id) => {
  return api.delete(`/tag/${id}`);
};
