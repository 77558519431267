const messages = {
  ar: {
    home: "الرئيسية",
    tables: "الطاولات",
    oppLanguage: "English",
    arabic: "العربية",
    english: "الإنجليزية",
    signout: "تسجيل الخروج",
    settings: "الإعدادات",
    signin: "تسجيل الدخول",
    signup: "إنشاء حساب",
    name: "ادخل اسمك",
    phone: "رقم الجوال",
    phone05: "5xxxxxxxx",
    emailPlaceholder: "test@gmail.com",
    email: "البريد الالكتروني",
    error: "خطأ",
    errorname: "يجب كتابة الاسم",
    errorphone: "5xxxxxxxx",
    erroremail: "تأكد من البريد الالكتروني",
    password: "الرقم السري",
    errorpassword: "الرقم السري يحب أن يكون أكثر من ٦ خانات",
    errorfetch: "خطأ في الاتصال, فضلاً أعد المحاولة لاحقاً",
    restaurantName: "اسم المطعم",
    nameAr: "الاسم بالعربي",
    nameEn: "الاسم بالإنجليزي",
    choose: "اختر",
    restaurant: "مطعم",
    delete: "حذف",
    status: "الحالة",
    edit: "تعديل",
    inHouse: "محلي",
    takeAway: "سفري",
    confirmDelete: "هل أنت متأكد من حذف هذا السجل ؟",
    cancel: "إلغاء",
    save: "حفظ",
    maxSelection: "الحد الأعلى من الخيارات",
    minSelection: "الحد الأدنى من الخيارات",
    code: "كود لوحة التحكم",
    errorCode: "الكود غير صحيح",
    enter: "ادخل",
    branches: "الفروع",
    waiters: "النوادل",
    payments: "الحسابات",
    subscription: "الاشتراك",
    addBranch: "أضف فرع",
    cityName: "اسم المدينة",
    tablesCount: "عدد الطاولات",
    startFrom: "البداية من",
    justName: "الاسم",
    confirmDeleteReferTitle: "هل أنت متأكد من حذف هذه الكود ؟",
    forgetPassword: "نسيت كلمة المرور",
    addCoupon: "أضف كوبون",
    justCode: "الكود",
    totalUsed: "عدد مرات الاستخدام",
    freeSelection: "عدد الاختيارات المجانية",
    disabled: "معطل",
    savedSuccessfully: "تم حفظ التغييرات بنجاح",
    Active: "نشطة",
    noModifiers: "لا يوجد أي إضافة",
    manage: "إدارة",
    foodicsInfo: "معلومات فودكس",
    marnInfo: "معلومات الربط مع نظام مرن",
    subscriptionEndDate: "نهاية الاشتراك",
    loadingData: "جاري تحميل البيانات",
    JUST_MENU: "المنيو",
    paymentRecords: "سجل المدفوعات",
    restaurants: "المطاعم",
    users: "المستخدمين",
    end: "ينتهي الاشتراك في ",
    noUser: "لا يوجد يوزر لهذا المطعم",
    ordersTotal: "عدد الطلبات",
    totalVisits: "عدد الزيارات",
    ordersTotalAmount: "الدخل",
    totals: "الإجمالي",
    paidInRestaurant: "الدفع في المطعم",
    paidOnline: "الدفع اونلاين",
    amount: "المبلغ",
    totalRevenue: "إجمالي الدخل",
    cash: "كاش",
    online: "اونلاين",
    info: "معلومات",
    orderType: "نوع الطلب",
    dateStart: "تاريخ البداية",
    dateEnd: "تاريخ النهاية",
    search: "بحث",
    ourShare: "حصتنا",
    thereShare: "حصتهم",
    subType: "الباقة",
    user: "العميل",
    gold: "الذهبية",
    silver: "الفضية",
    basic: "العادية",
    update: "تحديث",
    free: "المجانية",
    requests: "الطلبات",
    integration: "الربط",
    select: "اختيار",
    deliveryRequests: "طلبات التوصيل",
    posRequsets: "طلبات ربط النظام",
    request: "طلب جديد",
    foodicsMenu: "منيو فودكس",
    modifier: "النوع",
    readyForDeploy: "جاهز للربط",
    ready: "مربوط",
    active: "فعال",
    grabTax: "جلب الضريبة",
    bringDeliveryFees: "جلب بيانات التوصيل",
    createPayment: "إنشاء وسيلة دفع",
    refer: "الكوبونات",
    addRefer: "أضف كوبون",
    couponName: "اسم الكوبون , هذا الاسم سيكون مرجع لك",
    couponCode: "كوبون كود , هذا الكود الذي سيكتبه العميل",
    startDate: "تاريخ البداية",
    endDate: "تاريخ النهاية",
    percentage: "النسبة الخصم",
    numberOfFreeWeeks: "عدد الأسابيع المجانية",
    editRefer: "تعديل الكوبون",
    stop: "إيقاف",
    activate: "تفعيل",
    referCode: "كود التسجيل",
    menu: "المنيو",
    deleted: "محذوف",
    tapPayments: "اشتراك تاب",
    sms: "الرسائل النصية",
    paymentsTransactions: "سجل المدفوعات",
    paidStatus: "حالة الدفع",
    linkTable: "رابط للطاولة",
    privateWalletTap: "محفظة خاصة بالعميل من تاب",
    wallet: "المحفظة",
    pierodTime: "المدة المحول عنها",
    source: "الطريقة",
    transactionNumber: "رقم الحوالة",
    createdAt: "تاريخ الحوالة",
    company: "الشركة",
    foodicsFood: "اسم الاكلة في فودكس",
    modifierNameInFoodics: "اسم الاضافة في فودكس",
    marnFood: "اسم الاكلة في مرن",
    modifierNameInMarn: "اسم الاضافة في مرن",
    language: "اللغة",
    country: "الدولة",
    numberOfCashiers: "عدد الكاشيرات",
    numberOfBranches: "عدد الفروع",
    numberOfWaiters: "عدد الويترز",
    createdTime: "تاريخ الإنشاء",
    totalOrders: "عدد الطلبات الكلية",
    sentSuccessful: "عدد الطلبات المرسلة بنجاح",
    successfulRate: "نسبة النجاح",
    refresh: "تحديث",
    syncOrders: "مزامنة الطلبات",
    syncMenu: "مزامنة المنيو",
    deleteToken: "حذف التوكن",
    deleteAllTablesAndAddFromFoodics:
      "حذف كل الطاولات الحالية واضافتها من فودكس",
    addFoodicsMenu: "اضف منيو فودكس",
    message: "الرسالة",
    senderName: "اسم المرسل",
    send: "إرسال",
    amountToTransfer: "المبلغ المطلوب للتحويل",
    transferType: "طريقة التحويل",
    addTransaction: "إضافة حوالة",
    deleteIntegration: "حذف الربط",
    "PAY AS YOU GO": "PAY AS YOU GO",
    lessThanWeek: "أقل من أسبوع",
    expired: "منتهي",
    GOLD: "الذهبية",
    MENU: "المنيو",
    SILVER: "الفضية",
    "N/A": "N/A",
    settlement: "التسوية",
    dateRange: "التواريخ",
    uploadFile: "رفع ملف تاب",
    total: "الإجمالي",
    settlAmount: "المبلغ الواصل",
    remainAmount: "المبلغ المتبقي",
    date: "التاريخ",
    orderDate: "تاريخ الطلب",
    export: "تصدير",

    totalToNow: "مجموع المديونية",
    application: "التطبيق",
    pickupStatus: "حالة الاستلام",
    waitingStatus: "حالة الانتظار",
    reservationStatus: "حالة الحجز",
    allowedServices: "الخدمات المسموحة",
    images: "الصور",
    restaurantType: "نوع المطعم",
    saveSort: "حفظ الترتيب",
    sort: "الترتيب",
    usersApplication: "عملاء التطبيق",
    deviceType: "نوع الجهاز",
    title: "العنوان",
    body: "المحتوى",
    add: "إضافة",
    privateWalletMyFatoorah: "محفظة ماي فاتورة",
    checkAccount: "التحقق من الحساب",
    service: "الخدمة",
    services: "الخدمات",
    addNewService: "إضافة خدمة جديدة",
    discountAmount: "الخصم",
    amountPaid: "المبلغ المدفوع",
    originalPrice: "السعر الأساسي",
    paymentInformation: "بيانات الدفع",
    chooseBank: "اختر البنك",
    bankAccountName: "اسم الحساب البنكي باللغة الانجليزية",
    bankAccountNameTool:
      "اسم الحساب البنكي باللغة الانجليزية ، كما هو مسجل في شهادة الايبان",
    bankAccountNumber: "رقم الحساب البنكي",
    clickToUpload: "اضغط لرفع الملف",
    files: "الملفات",
    activatePayment: "تفعيل الدفع",
    CommercialRegister: "السجل التجاري",
    CommercialLicense: "رخصة البلدية",
    ArticlesOfAssociation: "عقد التأسيس - في حال كون المنشأة شركة-",
    ids: "صورة الهوية",
    BankAccountLetter: "شهادة الايبان باللغة الإنجليزية",
    switchToNewPayment:
      "الآن تستطيع الانتقال إلى بوابة الدفع الجديدة من اي ويتر، حيث ستكون نسبة المدفوعات الالكترونية هي 1.9% لكل عملية تتم عن طريق مدى ، و 2.4% لكل عملية تتم عن طريق فيزا وماستر كارد ، يمكنك تفعيل هذه الاتفاقية بتعبئة النموذج في أسفل هذه الصفحة.",
    newDeal: "إتفاقية جديدة",
    youCanUploadFilesAfterSavePayment:
      "تستطيع رفع الملفات بعد تفعيل الدفع وتقديم البيانات البنكية",
    iban: "الايبان",
    userName: "اسم المستخدم",
    settlements: "التسويات",
    nextDateForTransaction: "تاريخ الحوالة القادم",
    transferNumber: "رقم الحوالة",
    noData: "لا يوجد بيانات",
    bankName: "اسم البنك",
    MADATotal: "إجمالي مدى",
    VISATotal: "إجمالي فيزا",
    MADATransferTotal: "إجمالي مدى للتحويل",
    VISATransferTotal: "إجمالي فيزا للتحويل",
    officialName: "الاسم الرسمي",
    inApp: "مضاف للتطبيق",
    location: "الموقع",
    change: "تغيير",
    Suspended: "معلق",
    marketing: "التسويق",
    marketingEmail: "إرسال بريد",
    template: "القالب",
    subject: "الموضوع",
    // new data;
    students: "الطلاب",
    whtsapp: "الواتساب",
    actions: "الإجراءات",
    absence: "الغياب",
    latents: "التأخير",
    addStudent: "إضافة طالب",
    grade: "الصف",
    localId: "الرقم المدني",
    deleteStudent: "حذف الطالب",
    absenceReport: "تقرير الطلاب",
    role: "المنصب",
    track: "التتبع",
    titleOfEmail: "عنوان البريد",
    titleOfEmailRequired: "عنوان البريد مطلوب",
    emailContent: "البريد",
    emailContentRequired: "محتوى البريد مطلوب",
    doesIncludeCoupon: "هل يحتوي البريد على كوبون خصم",
    couponText: "كوبون الخصم",
    couponTextRequired: "كوبون الخصم مطلوب",
    yes: "نعم",
    no: "لا",
    success: "تمت العملية بنجاح",
    successUpdate: "تم تحديث البيانات بنجاح",
    initiated: "تم البدء",
    completed: "مكتملة",
    addedToCart: "تمت الإضافة للسلة",
    abandonedCart: "سلة متروكة",
    events: "الأحداث",
    mostVisited: "الأكثر زيارة",
    period: "الفترة",
    views: "مشاهدة",
    addToCart: "إضافة للسلة",
    purchase: "شراء",
    today: "اليوم",
    yesterday: "أمس",
    last7days: "آخر 7 أيام",
    last14days: "آخر 14 أيام",
    last30days: "آخر 30 أيام",
    thisMonth: "هذا الشهر",
    lastMonth: "الشهر الماضي",
    customTime: "وقت مخصص",
    abandonedCart: "سلة متروكة",
    products: "المنتجات",
    product: "المنتج",
    image: "الصورة",
    sentEmails: "البريد المرسل",
    emailSettings: "إعدادات البريد",
    account: "الحساب",
    store: "المتجر",
    package: "الباقة",
    editPassword: "تعديل كلمة المرور",
    oldPassword: "كلمة المرور القديمة",
    newPassword: "كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور",
    required: "مطلوب",
    newPassAndConfirmPassNotMatch:
      "كلمة المرور الجديدة وتأكيد كلمة المرور غير متطابقين",
    UserDoesNotExist: "المستخدم غير موجود",
    OldPasswordIsIncorrect: "كلمة المرور القديمة غير صحيحة",
    stores: "المتاجر",
    storeName: "اسم المتجر",
    monthlyLimitEmails: "الحد الشهري للبريد",
    storeProvider: "مزود المتجر",
    "n/a": "غير متوفر",
    domain: "الدومين",
    monthlyLimitEvents: "الحد الشهري للأحداث",
    customers: "العملاء",
    unsubscribe: "إلغاء الاشتراك",
    unsubscribeReason: "هل يمكنك إخبارنا سبب إلغاء الاشتراك؟",
    otherReason: "سبب آخر",
    unsubscribeSuccess: "تم إلغاء الاشتراك بنجاح",
    campaigns: "الحملات",
    welcomeEmailActive_text: "الترحيب بالعملاء الجدد",
    mostViewedProductEmailActive_text: "تذكير بمنتج تم تصفحه",
    addedToCartEmailActive_text: "تذكير بمنتج تمت اضافته للسلة",
    abandonedCartEmailActive_text: "تذكير بالسلة المتروكة",
    campaign: "الحملة",
    sentEmails: "عدد الايميلات المرسلة",
    customer: "العميل",
    stage: "المرحلة",
    templates: "القوالب",
    template: "القالب",
    back: "رجوع",
    customCampaigns: "الحملات الجماعية",
    campaignName: "اسم الحملة",
    count: "العدد",
    newCampaign: "حملة جديدة",
    addCampaign: "إضافة حملة",
    create: "إنشاء",
    campaignTime: "وقت الحملة",
    target: "المستهدفين",
    sallaClients: "عملاء سلة",
    customExcel: "ملف اكسل مخصص",
    both: "الكل",
    createTemplate: "إنشاء قالب",
    noTemplate: "لا يوجد لديك قوالب",
    customTemplate: "قالب مخصص",
    addTemplate: "إضافة قالب",
    templateName: "اسم القالب",
    subjectEmail: "عنوان الايميل",
    gender: "الجنس",
    male: "ذكر",
    female: "أنثى",
    blank: "فارغ",
    city: "المدينة",
    view: "عرض",
    customerPage: "صفحة العميل",
    testEmails: "اختبار الايميلات",
    group: "المجموعة",
    default: "الافتراضي",
    addTags: "إضافة متغيرات",
    value: "القيمة",
    sample: "مثال",
    add: "إضافة",
    pending: "مسودة",
    oneCampaignEmailsLimit: "الحد الأعلى الايميلات للحملة الواحدة",
    monthlyCampaignLimit: "الحد الشهري للحملات",
    free: "مجاني",
    basic: "أساسي",
    premium: "متقدم",
    enterprise: "مؤسسة",
    yourPlanIsForCustomCampaign: "باقتك الحالية هي { plan } ",
    yourPlanIsForCustomCampaignEmails:
      "باقتك الحالية هي { plan } وتستطيع إرسال { limit } ايميل للحملة الواحدة",
    autoCampaigns: "الحملات الآلية",
    senderEmail: "الإيميل المُرسل منه",
    bandedCarts: "السلال المتروكة",
    bandedBrowser: "المنتجات المتروكة",
    newCustomers: "العملاء الجدد",
    emailType: "نوع الايميل",
    sent: "تم الإرسال",
    open: "تم الفتح",
    clicked: "تم النقر",
    welcomeCustomer: "الترحيب بالعملاء الجدد",
    abandonedCart: "تذكير بالسلة المتروكة",
    completed: "تذكير بمنتج تم تصفحه",
    newPassword: "كلمة مرور جديدة",
    addedToCart: "تذكير بمنتج تمت اضافته للسلة",
    test: "اختبار",
    sentTest: "إرسال ايميل تجريبي ",
    templateLibrary: "مكتبة القوالب",
    copy: "نسخ",
    editTemplate: "تعديل القالب",
    newPhoto: "صورة جديدة",
    addPhoto: "إضافة صورة",
    unAvalibleInFreePlan: "غير متوفر في الباقة المجانية",
    addCustomPhoto: "إضافة صورة مخصصة",
    youDontHaveAccessToCustomCampaigns:
      "ليس لديك صلاحية الحملات المخصصة، قم بترقية اشتراكك",
    defaultTemplates: "القوالب الالية",
    choosePlan: "اختر الباقة",
    chooseDefaultTemplate: "اختر الحملة",
    "welcome-customer": "الترحيب بالعملاء الجدد",
    "welcome-customer-review": "الترحيب بالعملاء الجدد مع ارفاق تقييمات",
    view_item: "تذكير بمنتج تم تصفحه",
    view_item_review: "تذكير بمنتج تم تصفحه مع ارفاق تقييمات",
    "add-to-cart": "تذكير بمنتج تمت اضافته للسلة",
    "aband-cart": "تذكير بالسلة المتروكة",
    defaultTemplate: "القالب الأساسي",
    emailTitle: "عنوان الايميل",
    usedEmails: "عدد الايميلات المستخدمة",
    remainingEmails: "عدد الايميلات المتبقية",
    reachLessThan10:
      "قاربت على استخدام الحد الأقصى من الإيميلات في باقتك الحالية، تبقى لديك { remaining } ايميل",
    reachLimit: "لقد وصلت للحد الأقصى من الإيميلات في باقتك الحالية",
    upgradePlan: "قم بترقية اشتراكك الآن",
    upgrade: "ترقية",
    deliveredAndNotOpen: "تم وصولها للبريد",
    failed: "فشلت",

    deleteTempalte: "حذف القالب",
    deleteTemplateMessage: "هل انت متاكد من حذف هذا القالب ؟",
    templateDeletedSuccessfully: "تم حذف القالب بنجاح",

    downloadExcel: "تحميل العملاء",
    uploadClientToExcel: "تصدير العملاء",
    uploadCustomersSuccessfully: "تم تحميل العملاء بنجاح",
    newCustomerSuccess: "عملاء جدد بنجاح",
    AddedCustomer: "تمت إضافة",
    uploadNow: "تصدير العملاء الان",
    downloadTemplate: "تحميل قالب التصدير",
    caractereCount: "عدد الاحرف",
    editCampagn: "تعديل حملة",
    templateWarning: "قم بترقية الباقة لاستخدام القوالب الاحترافية",
    upgradeNow: "ترقية الباقة الآن",
    later: "لاحقاً",
    campaignWarning: "قم بترقية الباقة لتغيير القالب",
    loginWithEmail: "تسجيل الدخول بالبريد الإلكتروني",
    or: "أو",
    loginWithPhone: "تسجيل الدخول بالهاتف",
    errorEmail: "البريد الإلكتروني اجباري",
    errorEmailFormat: "البريد الإلكتروني خاطئ",
    fileName: "اسم الملف",
    validCustomers: "إجمالي العملاء",
    uploadCustomerFile: "اختيار  ملف العملاء",
    changeFile: "تغيير الملف ",
    saveFileCustomer: "هل تريد حفظ العملاء الجدد؟",
    dragAndDropFile: "انقر أو اسحب الملف إلى هذه المنطقة لتحميله",
    dragAndDropFileDescription:
      "قبول  فقط ملف .xlsx او.xls. ويمكنك إضافة ملف واحد فقط",
    excelFileAlert: "يرجى تغيير الملف، نحن نقبل فقط .xls أو .xlsx",
    excelNoCustomer:
      "لا يوجد عملاء في هذا الملف، يرجى تنزيل القالب للحصول على التنسيق الصحيح",
    fileRequired: "الملف اجباري",
    isSent: "أرسلت ",
    isDelivered: "وصلت ",
    openedCount: "مرات الفتح",
    clickedCount: "مرات النقر",
    cancelled: "ملغاة",
    done: "تم الارسال",
    scheduled: "مجدولة",
    viewStats: "الإحصائيات",
    campaignStats: "إحصائيات الحملة",
    emails: "بريد الإلكتروني",
    emailsPerformance: "أداء البريد الإلكتروني",
    deliveryRate: "معدل التسليم",
    openRate: "معدل الفتح",
    openClickRate: "معدل النقر | الفتح",
    lastEvent: "اخر تفاعل",
    eventDate: "تاريخ اخر تفاعل",
    opened: "تم الفتح",
    delivered: "تم التسليم",
    ErrorCustomCampaign: "خطأ في إرسال الحملة",
    campaignTimeWarningDescription:
      "عذراً لايمكن إرسال هذه الحملة، يمكنك ارسال حملة جماعية بعد 24 ساعة من وقت الحملة السابقة",
    monthlyLimitWarning:
      "هذه الحملة تتجاوز الحد الشهري الخاص بك ولكن يمكنك إرسال الحملة إلى",
    campaignConfirmMessage: "هل تريد تاكيد الارسال ؟",
    clients: "عميل ",
    resend: "تاكيد الإرسال",
    createCampaignSuccess: "تم إنشاء الحملة بنجاح",
    cancelCampaign: "إلغاء الحملة",
    cancelCampaignDescription: "هل أنت متأكد من أنك تريد إلغاء هذه الحملة؟",
    confirm: "تاكيد",
    excelFileRecap: "معلومات ملف الاكسل",
    ExcelAddedCustomers: "العملاء الجدد الذي  تم اضافتهم",
    ExceledExistingCustomers: "العملاء الموجودين في قاعدة البيانات",
    ExceledUpdatedCustomers: "العملاء الذي تم تحديثهم",
    tags: "الاوسمة",
    tag1: "وسم 1",
    tag2: "وسم 2",
    tag3: "وسم 3",
    addTag: "اضف وسم",
    typeTag: "نوع الوسم",
    editCustomer: "تعديل العميل",
    tagsSetting: "إعدادات الاوسمة",
    deleteTag: "حذف وسم",
    deleteTagConfirmation: "هل انت متاكد من حذف هذا الوسم  ؟",
    reset: "إعادة تعيين",
    customerSearchPlaceholder: "البحث بالاسم أو البريد الإلكتروني أو الهاتف",
    noTagsAdded: "لم يتم اضافة اوسمة",
    filter: "الفلترة",
    customerUpdated: "تم تحديث العميل بنجاح",
    customerGroups: "مجموعات العملاء ",
    addGroup: "مجموعة جديدة",
    editGroup: "تعديل مجموعة",
    groupeName: "اسم المجموعة",
    groupConditions: "شروط المجموعة",
    groupConditionsDescription: "سيتم إضافة الأشخاص الذين تنطبق عليهم الشروط إلى المجموعة.",
    selectGroupCondition: "اختر الشروط",
    deleteGroup: " حذف المجموعة",
    deleteGroupDescription: "هل أنت متأكد من أنك تريد إلغاء هذه المجموعة ؟",
    noTag: "بدون وسم"
  },
  en: {
    failed: "Failed",
    deliveredAndNotOpen: "Delivered",
    upgradePlan: "Upgrade your subscription now",
    upgrade: "Upgrade",
    reachLessThan10:
      "You are about to reach the maximum limit of emails in your current plan, you have {remaining} emails left",
    reachLimit:
      "You have reached the maximum limit of emails in your current plan",
    usedEmails: "Used Emails",
    remainingEmails: "Remaining Emails",
    emailTitle: "Email Title",
    defaultTemplate: "Default Template",
    "welcome-customer": "Welcome Customer",
    "welcome-customer-review": "Welcome Customer with Review",
    view_item: "View Item",
    view_item_review: "View Item with Review",
    "add-to-cart": "Add to Cart",
    "aband-cart": "Abandoned Cart",
    chooseDefaultTemplate: "Choose Campaign",
    choosePlan: "Choose Plan",
    defaultTemplates: "Default Templates",
    youDontHaveAccessToCustomCampaigns:
      "You don't have access to custom campaigns, upgrade your subscription",
    test: "Test",
    addCustomPhoto: "Add Custom Photo",
    unAvalibleInFreePlan: "Unavalible in free plan",
    newPhoto: "New Photo",
    addPhoto: "Add Photo",
    editTemplate: "Edit Template",
    copy: "Copy",
    templateLibrary: "Template Library",
    sentTest: "Send Test Email",
    welcomeCustomer: "New Customer",
    addedToCart: "Added to Cart",
    newPassword: "New Password",
    abandonedCart: "Abandoned Cart",
    completed: "Abandoned Browser",
    value: "Value",
    emailType: "Email Type",
    sent: "Sent",
    open: "Open",
    clicked: "Clicked",
    bandedCarts: "Banded Carts",
    bandedBrowser: "Banded Browser",
    newCustomers: "New Customers",
    senderEmail: "Sender Email",
    autoCampaigns: "Auto Campaigns",
    yourPlanIsForCustomCampaignEmails:
      "Your plan is {plan} and you can send {limit} emails for one campaign",
    yourPlanIsForCustomCampaign:
      "Your plan is {plan} and you can create {limit} campaigns monthly",
    free: "Free",
    basic: "Basic",
    premium: "Premium",
    enterprise: "Enterprise",
    oneCampaignEmailsLimit: "One Campaign Emails Limit",
    monthlyCampaignLimit: "Monthly Campaign Limit",
    pending: "Pending",
    sample: "Sample",
    add: "Add",
    yes: "Yes",
    addTags: "Add Tags",
    default: "Default",
    group: "Group",
    testEmails: "Test Emails",
    customerPage: "Customer Page",
    view: "View",
    city: "City",
    gender: "Gender",
    male: "Male",
    female: "Female",
    blank: "Blank",
    subjectEmail: "Email Subject",
    templateName: "Template Name",
    customTemplate: "Custom Template",
    addTemplate: "Add Template",
    createTemplate: "Create Template",
    noTemplate: "You don't have any templates",
    customExcel: "Custom Excel",
    both: "Both",
    sallaClients: "Salla Clients",
    target: "Target",
    campaignTime: "Campaign Time",
    create: "Create",
    newCampaign: "New Campaign",
    addCampaign: "Add Campaign",
    campaignName: "Campaign Name",
    count: "Count",
    customCampaigns: "Custom Campaigns",
    back: "Back",
    templates: "Templates",
    template: "Template",
    stage: "Stage",
    customer: "Customer",
    campaign: "Campaign",
    sentEmails: "Count of Sent Emails",
    welcomeEmailActive_text: "Campaign for new customers",
    mostViewedProductEmailActive_text: "Campaign for most viewed products",
    addedToCartEmailActive_text: "Campaign for added to cart products",
    abandonedCartEmailActive_text: "Campaign for abandoned cart",
    campaigns: "Campaigns",
    unsubscribeSuccess: "Unsubscribe successfully",
    otherReason: "Other reason",
    unsubscribeReason: "Can you tell us why you unsubscribed?",
    unsubscribe: "Unsubscribe",
    customers: "Customers",
    monthlyLimitEvents: "Monthly limit events",
    domain: "Domain",
    "n/a": "N/A",
    storeProvider: "Store Provider",
    monthlyLimitEmails: "Monthly limit emails",
    storeName: "Store Name",
    stores: "Stores",
    UserDoesNotExist: "User does not exist",
    OldPasswordIsIncorrect: "Old password is incorrect",
    newPassAndConfirmPassNotMatch:
      "New password and confirm password not match",
    required: "Required",
    oldPassword: "Old Password",
    newPassword: "New Password",
    confirmPassword: "Confirm Password",
    editPassword: "Edit Password",
    package: "Package",
    store: "Store",
    account: "Account",
    emailSettings: "Email Settings",
    product: "Product",
    image: "Image",
    sentEmails: "Sent Emails",
    products: "Products",
    abandonedCart: "Abandoned cart",
    today: "Today",
    yesterday: "Yesterday",
    last7days: "Last 7 days",
    last14days: "Last 14 days",
    last30days: "Last 30 days",
    thisMonth: "This month",
    lastMonth: "Last month",
    customTime: "Custom Time",
    loadingData: "Loading data",
    purchase: "Purchase",
    period: "Period",
    mostVisited: "Most visited",
    events: "Events",
    abandonedCart: "Abandoned cart",
    initiated: "Initiated",
    completed: "Completed",
    addedToCart: "Added to cart",
    successUpdate: "Data updated successfully",
    success: "Operation done successfully",
    couponText: "Coupon",
    couponTextRequired: "Coupon is required",
    no: "No",
    titleOfEmail: "Title of email",
    titleOfEmailRequired: "Title of email is required",
    emailContent: "Email content",
    emailContentRequired: "Email content is required",
    students: "Students",
    track: "Track",
    role: "Role",
    absenceReport: "Students report",
    deleteStudent: "Delete Student",
    localId: "Local ID",
    grade: "Grade",
    addStudent: "Add Student",
    latents: "Latents",
    absence: "Absence",
    actions: "Actions",
    whtsapp: "Whtsapp",
    // old data
    ordersTotal: "Total orders",
    subject: "Subject",
    template: "Template",
    marketingEmail: "Send email",
    marketing: "Marketing",
    Suspended: "Suspended",
    change: "Change",
    location: "Locations",
    inApp: "Added to the application",
    officialName: "Official name",
    MADATotal: "MADA Total",
    VISATotal: "VISA Total",
    MADATransferTotal: "MADA Transfer Total",
    VISATransferTotal: "VISA Transfer Total",
    bankName: "Bank Name",
    noData: "No Data",
    transferNumber: "Transfer Number",
    yourBalance: "Your Balance",
    settlements: "Settlement",
    userName: "User name",
    iban: "IBAN",
    youCanUploadFilesAfterSavePayment:
      "You can upload files after activate payment and submit bank information",
    newDeal: "New Deal",

    CommercialRegister: "Commercial Register",
    CommercialLicense: "Commercial License",
    ArticlesOfAssociation:
      "Articles Of Association - in case the register is a company -",
    ids: "Copy of ID",
    BankAccountLetter: "Bank Account Letter in English",
    files: "Files",
    activatePayment: "Activate Payment",
    clickToUpload: "Click to upload",
    bankAccountName: "Bank account name in English",
    bankAccountNameTool:
      "Bank account name in English as registered in the Iban certificate",
    bankAccountNumber: "Bank account number",
    chooseBank: "Choose Bank",
    paymentInformation: "Payment Information",
    onlinePayment: "Online Payment",
    originalPrice: "Original price",
    discountAmount: "Discount amount",
    amountPaid: "Amount paid",
    addNewService: "Add new service",
    service: "Service",
    services: "Services",
    checkAccount: "Check Account",
    privateWalletMyFatoorah: "My Fatoorah Wallet",
    add: "Add",
    deviceType: "Device type",
    title: "Title",
    body: "Body",
    usersApplication: "Users application",
    sort: "Sort",
    saveSort: "Save sort",
    totalToNow: "Total to now",
    restaurantType: "Restaurant Type",
    pickupStatus: "Pickup status",
    waitingStatus: "Waiting status",
    reservationStatus: "Reservation status",
    allowedServices: "Allowed services",
    images: "Images",
    application: "Application",
    startSubscriptionDate: "Start subscription",
    paid: "Paid",
    notPaid: "Not paid",
    settlementInfo: "Settlement info",
    pendingPayments: "Pending payments",
    sendSubscriptionEnd: "Send reminder about subscription end",
    export: "Export",
    orderDate: "Order date",
    date: "Date",
    total: "Total",
    settlAmount: "Settled amount",
    remainAmount: "Remaining amount",
    uploadFile: "Upload TAP file",
    dateRange: "Date range",
    settlement: "Settlement",
    "N/A": "N/A",
    GOLD: "GOLD",
    MENU: "MENU",
    SILVER: "SILVER",
    lessThanWeek: "Less than a week",
    expired: "Expired",
    "PAY AS YOU GO": "PAY AS YOU GO",
    deleteIntegration: "Delete integration",
    addTransaction: "Add transaction",
    transferType: "Transfer type",
    amountToTransfer: "Amount to transfer",
    messages: "Message",
    senderName: "Sender name",
    send: "Send",
    addFoodicsMenu: "Add Foodics Menu",
    deleteAllTablesAndAddFromFoodics: "Delete all tables and add from Foodics",
    deleteToken: "Delete token",
    syncOrders: "Sync orders",
    syncMenu: "Sync menu",
    refresh: "Refresh",
    totalOrders: "Total orders",
    sentSuccessful: "Total sent successful orders",
    successfulRate: "Successful rate",
    createdTime: "Created time",
    numberOfCashiers: "Number of cashiers",
    numberOfBranches: "Number of branches",
    numberOfWaiters: "Number of waiters",
    country: "Country",
    language: "Language",
    foodicsFood: "Food name in Foodics",
    modifierNameInFoodics: "Modifier name in Foodics",
    marnFood: "Food name in Marn",
    modifierNameInMarn: "Modifier name in Marn",
    company: "Company",
    createdAt: "Created at",
    transactionNumber: "Transaction number",
    source: "Source",
    pierodTime: "Pierod time",
    wallet: "Wallet",
    totalAmount: "Paid amount",
    linkTable: "Table link",
    paidStatus: "Payment status",
    paymentsTransactions: "Payments transactions",
    sms: "SMS",
    tapPayments: "Tap Payments",
    deleted: "deleted",
    menu: "menu",
    referCode: "Refer code",
    activate: "Activate",
    stop: "Stop",
    editRefer: "Edit Refer",
    numberOfFreeWeeks: "number Of Free Weeks",
    percentage: "Discount percentage",
    couponName: "Coupon name, this name for your reference",
    couponCode: "Coupon code, this code the customer will type.",
    startDate: "Start date",
    endDate: "End date",
    addRefer: "Add refer code",
    refer: "Coupons",
    createPayment: "Create payment method",
    bringDeliveryFees: "Bring Delivery Fees",
    grabTax: "Grab Tax",
    readyForDeploy: "ready For integrate",
    ready: "Ready",
    active: "Active",
    modifier: "Modifier",
    foodicsMenu: "Foodics Menu",
    request: "New request",
    deliveryRequests: "Delivery requests",
    posRequsets: "POS requests",
    select: "Select",
    integration: "Integration",
    requests: "requests",
    free: "Free",
    update: "Update",
    gold: "Glod",
    silver: "Silver",
    basic: "Basic",
    user: "User",
    subType: "subscription type",
    ourShare: "Our share",
    thereShare: "there share",
    orderType: "Order type",
    search: "Search",
    info: "Information",
    dateEnd: "End date",
    dateStart: "Start date",
    cash: "Cash",
    online: "Online",
    totalRevenue: "Total Revenue",
    amount: "Amount",
    paidInRestaurant: "paid in Restaurant",
    paidOnline: "Paid online",
    totals: "Total",
    ordersTotalAmount: "Revenue",
    totalVisits: "Total visits",
    restaurants: "Restaurants",
    end: "Subscription end on ",
    noUser: "No user with this restaurnat",
    users: "Users",

    paymentRecords: "Payment Records",

    JUST_MENU: "Menu",

    BASIC: "Standard",

    loadingData: "Loading data",
    subscriptionEndDate: "subscription end",

    marnInfo: "Marn information",

    foodicsInfo: "Foodics Information",
    manage: "Manage",

    noModifiers: "No modifiers",

    Active: "Active",

    savedSuccessfully: "change has been saved successfully",

    disabled: "disabled",

    freeSelection: "Number of free choices",

    goBack: "Back",

    totalUsed: "Total used",
    justCode: "Code",
    addCoupon: "Add coupon",

    home: "Home",
    tables: "Tables",
    oppLanguage: "عربي",
    settings: "Settings",
    signout: "Sign out",
    signin: "Sign in",
    signup: "Sign up",
    name: "Name",
    phone: "Mobile Number",
    phone05: "5xxxxxxxx",
    email: "Email",
    error: "Error",
    errorname: "Name is required",
    errorphone: "5xxxxxxxx",
    erroremail: "Please check your email",
    password: "Password",
    errorpassword: "Minimum length is 6 characters",
    errorfetch: "There was an error in the connection please try again.",
    restaurantName: "Restaurant name",
    nameAr: "Name in Arabic",
    nameEn: "Name in English",

    choose: "Choose",
    restaurant: "Restaurant",

    delete: "Delete",

    status: "Status",
    edit: "Edit",
    inHouse: "Dine in",
    takeAway: "Take away",
    confirmDelete: "Are you sure you want to delete this record ?",
    cancel: "Cancel",

    save: "Save",

    maxSelection: "Maximum choices",
    minSelection: "Minimum choices",

    code: "Enter your dashboard pin code",
    errorCode: "Code is not correct",
    enter: "Enter",

    branches: "Branches",
    waiters: "Waiters",

    payments: "Payments",
    subscription: "Subscription",

    addBranch: "Add Branch",
    cityName: "City name",
    tablesCount: "Tables count",
    startFrom: "Start from",

    justName: "Name",

    confirmDeleteReferTitle: "Are you sure you want to Delete this code ?",

    forgetPassword: "Forget password",

    deleteTempalte: "Delete Template",
    deleteTemplateMessage: "Are you sure you want to delete this template ? ",
    templateDeletedSuccessfully: "Template deleted successfully",

    downloadExcel: "Download Customer",
    uploadClientToExcel: "Upload Customer",
    uploadCustomersSuccessfully: "Uploaded Customer Successfully",
    newCustomerSuccess: "New Customers Added Successfully",
    AddedCustomer: " ",
    uploadNow: "Upload Customers Now",
    downloadTemplate: "Download upload template",
    caractereCount: "character count",
    editCampagn: "Edit Campaign",
    templateWarning: "Upgrade to use professional templates",
    upgradeNow: "Upgrade now",
    later: "Later",
    campaignWarning: "Upgrade to change template",
    loginWithEmail: "Login with email",
    or: "Or",
    loginWithPhone: "Login with phone",
    errorEmail: "Email is required",
    errorEmailFormat: "Email wrong format",
    fileName: "File name",
    validCustomers: "Total valid customers",
    uploadCustomerFile: "Upload File",
    changeFile: "Change File",
    saveFileCustomer: "Do you want to save new customers?",
    dragAndDropFile: "Click or drag the file into this area to upload it.",
    dragAndDropFileDescription:
      "Only .xlsx or .xls files are accepted. You can add only one file.",
    excelFileAlert: "Please change the file, we only accept .xls or .xlsx",
    excelNoCustomer:
      "There are no clients in this file, please download the template for the correct format.",
    fileRequired: "File required",
    isSent: "Sent ",
    isDelivered: "Delivered ",
    openedCount: "Open count",
    clickedCount: "click count",
    cancelled: "Cancelled",
    done: "Done",
    scheduled: "Scheduled",
    viewStats: "Statistics",
    campaignStats: "Campaign Statistics",
    emails: "Emails",
    emailsPerformance: "Email performances",
    deliveryRate: "Delivery rate",
    openRate: "Open rate",
    openClickRate: "Open | Click rate",
    lastEvent: "Last Event",
    eventDate: "Event date",
    opened: "Opened",
    delivered: "Delivered",
    ErrorCustomCampaign: "Error sending campaign",
    campaignTimeWarningDescription:
      "Sorry, this campaign cannot be sent. You can send a mass campaign after 24 hours from the previous campaign time.",
    monthlyLimitWarning:
      "This campaign is over your monthly limit but you can send the campaign to:",
    campaignConfirmMessage: "Do you confirm sending campaign  ? ",
    clients: "Clients",
    resend: "Confirm Send",
    createCampaignSuccess: "Campaign created successfully",
    cancelCampaign: "Cancel campaign",
    cancelCampaignDescription:
      "Are you sure , you want to cancel this campaign ? ",
    confirm: "Confirm",
    excelFileRecap: "File excel details",
    ExcelAddedCustomers: "New added customers",
    ExceledExistingCustomers: "Existing customers",
    ExceledUpdatedCustomers: "Updated customers",
    tags: "Tags",
    tag1: "Tag 1",
    tag2: "Tag 2",
    tag3: "Tag 3",
    addTag: "Add new tag",
    typeTag: "Tag type",
    editCustomer: "Edit customer",
    tagsSetting: "Tags settings",
    deleteTag: "Delete tag",
    deleteTagConfirmation: "Are you sure , you want to delete this tag ?",
    reset: "Reset",
    customerSearchPlaceholder: "Search by name , email or phone",
    noTagsAdded: "No tag added",
    filter: "Filter",
    customerUpdated: "Customer updated successfully",
    customerGroups: "Customer's groups ",
    addGroup: "Add new group",
    editGroup: "Edit group",
    groupeName: "Group name",
    groupConditions: "Group conditions",
    groupConditionsDescription: "Applied Conditions 'll add specific customers to group",
    selectGroupCondition: "Select conditions",
    deleteGroup: "Delete group",
    deleteGroupDescription: "Are you sure , you want to delete this group ? ",
    noTag: "Without Tag"
  },
};

export default messages;
