import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import * as XLSX from "xlsx";
import {
  Button,
  Dropdown,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from "antd";
//API and Redux
import {
  exportCustomerToExcel,
  getStoreCustomers,
  getStoreGroups,
  importCustomerFromExcel,
} from "../../API/fetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setStoreCustomersAction,
  setStoreGroupsAction,
  setStoreTagsAction,
} from "../../redux/reducers/storeReducer";
//Components
import openNotification from "../../Components/Notifications";
import CustomButton from "../../Components/CustomButton";
//helper data
import COLORS from "../../Style/colors";
import {
  AiOutlineUpload,
  AiOutlineDownload,
  AiOutlineCloudDownload,
  AiOutlineCloudUpload,
} from "react-icons/ai";
import CustomModal from "../../Components/CustomModal/CustomModal";
import { AiOutlineLine } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import Search from "./components/Search";
import CustomerEdit from "./components/CustomerEdit";
import GroupSection from "./components/GroupSection";

const { Title, Text } = Typography;

function Customers() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { rtl } = useSelector((state) => state.settings);
  const { store, groups } = useSelector((state) => state.store);
  const [loading, setLoading] = useState(false);
  const [downloadExcel, setDownloadExcel] = useState(false);
  const [uploadExcel, setUploadExcel] = useState(false);
  const [result, setResult] = useState({
    added: 0,
    updated: 0,
    existing: 0,
  });
  const [showResult, setShowResult] = useState(false);
  const [total, setTotal] = useState(0);
  const [showEditCustomer, setShowEditCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [page, setPage] = useState(1);
  const [length, setLength] = useState(50);
  const [customers, setCustomers] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    gender: [],
    cities: [],
    groups: [],
    tags1: [],
    tag2: [],
    tag3: [],
  });

  const formatQueryString = (values) => {
    const params = new URLSearchParams();

    // Convert each key-value pair to query parameters
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (Array.isArray(value)) {
        value.forEach((v) => params.append(key, v)); // Convert arrays properly
      } else if (value) {
        params.append(key, value);
      }
    });

    const queryString = params.toString();
    return queryString;
  };


  const getAllGroups = async () => {
    try {
      const res = await getStoreGroups();
      if (res?.data?.groups) {
        dispatch(setStoreGroupsAction(res?.data?.groups));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomers = async (p, l) => {
    try {
      setLoading(true);
      let pg = p || page;
      let lg = l || length;
      const query = formatQueryString(filters);
      const res = await getStoreCustomers(pg, lg, query);
      if (res?.data?.customers) {
        const { customers } = res.data;
        setCustomers(customers);
        dispatch(setStoreCustomersAction(res?.data?.customers));
        setTotal(res?.data?.total);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadExcel(true);
      const res = await exportCustomerToExcel();
      if (res?.data?.customers) {
        const selectedFields = res.data.customers.map(
          ({ name, phone, email }) => ({
            name,
            phone,
            email,
          })
        );
        const worksheet = XLSX.utils.json_to_sheet(selectedFields);

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");

        XLSX.writeFile(workbook, "CustomersData.xlsx");
        setDownloadExcel(false);
        openNotification({
          title: intl.formatMessage({ id: "uploadCustomersSuccessfully" }),
          type: "success",
          rtl,
        });
      }
    } catch (error) {
      console.log(error);
      setDownloadExcel(false);
      openNotification({
        title: intl.formatMessage({ id: "error" }),
        type: "error",
        description: "something went wrong",
        rtl,
      });
    }
  };

  const handleUpload = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const customers = XLSX.utils.sheet_to_json(sheet);
        try {
          const filterdCustomers = customers.map((obj) => {
            const keysToKeep = [
              "name",
              "email",
              "tag1",
              "tag2",
              "tag3",
              "phone",
            ];
            return keysToKeep.reduce((filteredObj, key) => {
              if (obj[key] !== undefined) {
                filteredObj[key] = obj[key];
              }
              return filteredObj;
            }, {});
          });
          setUploadExcel(true);
          const res = await importCustomerFromExcel({
            customers: filterdCustomers,
          });
          if (res.data.status === "success") {
            const { added, updated, existing, tags } = res.data;
            if (tags) {
              const tags1 = tags.filter((tag) => tag.type === "tag1");
              const tags2 = tags.filter((tag) => tag.type === "tag2");
              const tags3 = tags.filter((tag) => tag.type === "tag3");
              dispatch(setStoreTagsAction({ tags1, tags2, tags3 }));
            }
            setResult({
              added,
              updated,
              existing,
            });
            await getAllCustomers(1);
            setShowResult(true);
            setUploadExcel(false);
          }
        } catch (error) {
          // setUploadExcel(false);
          // openNotification({
          //   title: intl.formatMessage({ id: "error" }),
          //   type: "error",
          //   description: "something went wrong",
          //   rtl,
          // });
        }
      };
      reader.readAsBinaryString(file);
    }
    return false;
  };

  useEffect(() => {
    if (store?._id) {
      getAllCustomers(page);
      getAllGroups();
    }
  }, [store?._id, filters, page]);


  const props = {
    beforeUpload: (file) => handleUpload(file),
    accept: ".xlsx, .xls",
  };

  const downloadTemplate = () => {
    const headers = [["name", "email", "phone", "tag1", "tag2", "tag3"]];
    const worksheet = XLSX.utils.aoa_to_sheet(headers);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "CustomerTemplate.xlsx");
  };

  const items = [
    {
      key: "1",
      label: (
        <Upload showUploadList={false} {...props}>
          <Button icon={<AiOutlineCloudUpload />} loading={uploadExcel}>
            {intl.formatMessage({ id: "uploadNow" })}
          </Button>
        </Upload>
      ),
    },
    {
      key: "2",
      label: (
        <Button icon={<AiOutlineCloudDownload />} onClick={downloadTemplate}>
          {intl.formatMessage({ id: "downloadTemplate" })}
        </Button>
      ),
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: intl.formatMessage({ id: "justName" }),
      dataIndex: "name",
      key: "name",
    },
    {
      title: intl.formatMessage({ id: "email" }),
      dataIndex: "email",
      key: "email",
    },
    // {
    //   title: intl.formatMessage({ id: "phone" }),
    //   dataIndex: "phone",
    //   key: "phone",
    //   render: (text) => {
    //     return text ? <Text>{text}</Text> : <AiOutlineLine />;
    //   },
    // },
    {
      title: intl.formatMessage({ id: "gender" }),
      dataIndex: "gender",
      key: "gender",
      render: (text) => {
        return text ? <Text>{text}</Text> : <AiOutlineLine />;
      },
    },
    {
      title: intl.formatMessage({ id: "city" }),
      dataIndex: "city",
      key: "city",
      render: (text) => {
        return text ? <Text>{text}</Text> : <AiOutlineLine />;
      },
    },

    {
      title: intl.formatMessage({ id: "tag1" }),
      dataIndex: "tag1",
      key: "tag1",
      render: (text, record) => {
        return (
          <Tag color="geekblue">{record.tag1 ? record.tag1.title : null}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "tag2" }),
      dataIndex: "tag2",
      key: "tag2",
      render: (text, record) => {
        return (
          <Tag color="purple">{record.tag2 ? record.tag2.title : null}</Tag>
        );
      },
    },
    {
      title: intl.formatMessage({ id: "tag3" }),
      dataIndex: "tag3",
      key: "tag3",
      render: (text, record) => {
        return <Tag color="cyan">{record.tag3 ? record.tag3.title : null}</Tag>;
      },
    },
    {
      title: intl.formatMessage({ id: "group" }),
      dataIndex: "tag3",
      key: "tag3",
      render: (text, record) => {
        return <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {record.groups.length ? record.groups.map((group, index) => <Tag key={index} >{group.name}</Tag>) :
            <Tag />}
        </div>;
      },
    },
    {
      key: "action",
      render: (_text, record) => {
        return (
          <Tooltip title={intl.formatMessage({ id: "edit" })}>
            <Button
              shape="square"
              variant="outline"
              icon={<FiEdit />}
              onClick={() => {
                setSelectedCustomer(record);
                setShowEditCustomer(true);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  return (
    <div className="tables-card-container" style={{ position: "relative" }}>
      <CustomModal
        title={intl.formatMessage({ id: "excelFileRecap" })}
        body={
          <div>
            <Text>
              {intl.formatMessage({ id: "ExcelAddedCustomers" })} :{" "}
              <Tag color="green">{result.added} </Tag>
            </Text>
            <br />
            <Text>
              {intl.formatMessage({ id: "ExceledExistingCustomers" })} :{" "}
              <Tag color="green">{result.existing} </Tag>
            </Text>
            <br />
            <Text>
              {intl.formatMessage({ id: "ExceledUpdatedCustomers" })} :{" "}
              <Tag color="green">{result.updated} </Tag>
            </Text>
          </div>
        }
        open={showResult}
        setOpen={setShowResult}
        showButton={false}
        type="warning"
      />
      <CustomModal
        title={intl.formatMessage({ id: "editCustomer" })}
        body={
          <CustomerEdit
            selectedCustomer={selectedCustomer}
            setShowEditCustomer={setShowEditCustomer}
            getCustomers={getAllCustomers}
          />
        }
        open={showEditCustomer}
        setOpen={setShowEditCustomer}
        showButton={false}
        showFooter={false}
      />
      <div
        style={{
          // width: "100%",
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Title level={3} style={{ margin: 0 }}>
          {intl.formatMessage({ id: "customers" })}
        </Title>
        <div style={{ display: "flex", gap: "10px" }}>
          <Space wrap>
            <Dropdown menu={{ items }} placement="bottomRight">
              <Button icon={<AiOutlineUpload />}>
                {intl.formatMessage({ id: "uploadClientToExcel" })}
              </Button>
            </Dropdown>
          </Space>
          <CustomButton
            text={intl.formatMessage({ id: "downloadExcel" })}
            onClick={handleDownload}
            className={`btnRegister btn-text`}
            loading={downloadExcel}
            type="primary"
            icon={<AiOutlineDownload />}
          />
        </div>
      </div>
      <GroupSection
        groups={groups}
        getCustomers={getAllCustomers}
      />
      <div
        style={{
          marginTop: "30px",
          marginBottom: 20,
          width: "92%",
          marginRight: "auto",
          marginLeft: "auto",
          display: "flex",
        }}
      >
        <Search
          loading={loading}
          search={filters}
          setSearch={setFilters}
          setPage={setPage}
        />
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            backgroundColor: COLORS.white,
            marginTop: 5,
            width: "92%",
            marginRight: "auto",
            marginLeft: "auto",
            borderRadius: 10,
            padding: 15,
          }}
          className="light-shadow"
        >
          <Table
            dataSource={customers?.map((t) => {
              return {
                ...t,
                key: t?._id,
              };
            })}
            columns={columns}
            pagination={{
              total,
              pageSize: length,
              current: page,
              onChange: (page, length) => {
                setPage(page);
                setLength(length);
                getAllCustomers(page, length);
              },
            }}
            scroll={{ x: 800 }}
          />
        </div>
      </Spin>
    </div>
  );
}

export default Customers;
