import { Divider, Form, Select, Typography } from "antd";
import React, { useEffect } from "react";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

const { Option } = Select;
const { Title } = Typography;
function Filter({ search, setSearch, toggle, setToggle, form ,setPage  }) {
  const intl = useIntl();
  // const [form] = Form.useForm();
  const { tags, cities, groups } = useSelector((state) => state.store);
  const onFinish = async (values) => {
    setPage(1)
    setSearch({
      ...search,
      gender: values.gender,
      cities: values.cities,
      groups :values.groups,
      tag1: values.tag1,
      tag2: values.tag2,
      tag3: values.tag3,
    });
    setToggle(false);
  };
  useEffect(() => {
    if (search && toggle) {
      form.setFieldsValue({
        gender: search.gender,
        cities: search.cities,
        groups : search.groups,
        tag1: search.tag1,
        tag2: search.tag2,
        tag3: search.tag3,
      });
    }
  }, [search, form, toggle]);

  const resetFilter = () => {
    setPage(1)
    form.resetFields();
    setSearch({
      ...search,
      gender: [],
      cities: [],
      groups: [],
      tag1: [],
      tag2: [],
      tag3: [],
    });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="filter-customer"
      onFinish={onFinish}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
      initialValues={{
        gender: [],
        cities: [],
        groups: [],
        tag1: [],
        tag2: [],
        tag3: [],
      }}
    >
      <Form.Item
        label={intl.formatMessage({ id: "gender" })}
        style={{ margin: "0 0 8px 0" }}
        name="gender"
      >
        <Select mode="multiple" className="my-font">
          <Option value="male" className="my-font">
            {intl.formatMessage({ id: "male" })}
          </Option>
          <Option value="female" className="my-font">
            {intl.formatMessage({ id: "female" })}
          </Option>
          <Option value="" className="my-font">
            {intl.formatMessage({ id: "blank" })}
          </Option>
        </Select>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: "city" })} name="cities" style={{ margin: "0 0 8px 0" }}>
        <Select className="my-font" mode="multiple">
          {cities.map((city, index) => (
            <Option value={city} className="my-font" key={index}>
              {city}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: "group" })} name="groups" style={{ margin: "0 0 10px 0" }}>
        <Select className="my-font" mode="multiple">
          {groups.map((group, index) => (
            <Option value={group._id} className="my-font" key={index}>
              {group.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider plain style={{ margin: 0 }}>
        <Title level={5}>{intl.formatMessage({ id: "tags" })}</Title>
      </Divider>
      <Form.Item label={intl.formatMessage({ id: "tag1" })} name="tag1" style={{ margin: "0 0 8px 0" }}>
        <Select className="my-font" mode="multiple">
          {tags.tags1.map((tag, index) => (
            <Option value={tag._id} className="my-font" key={index}>
              {tag.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: "tag2" })} name="tag2" style={{ margin: "0 0 8px 0" }}>
        <Select className="my-font" mode="multiple">
          {tags.tags2.map((tag, index) => (
            <Option value={tag._id} className="my-font" key={index}>
              {tag.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label={intl.formatMessage({ id: "tag3" })} name="tag3" style={{ margin: "0 0 8px 0" }}>
        <Select className="my-font" mode="multiple">
          {tags.tags3.map((tag, index) => (
            <Option value={tag._id} className="my-font" key={index}>
              {tag.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Divider />
      <div style={{ display: "flex", gap: "8px", width: "100%" }}>
        <CustomButton
          text={intl.formatMessage({ id: "search" })}
          type="primary"
          htmlType="submit"
          loading={false}
          style={{ height: "36px", width: "100%" }}
          size="large"
        />
        <CustomButton
          text={intl.formatMessage({ id: "reset" })}
          loading={false}
          style={{ height: "36px", width: "100%" }}
          size="medium"
          onClick={resetFilter}
        />
      </div>
    </Form>
  );
}

export default Filter;
