import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import openNotification from "../../../Components/Notifications";
import { Divider, Form, Input, Popconfirm, Typography } from "antd";
import CustomButton from "../../../Components/CustomButton";
import { TreeSelect } from "antd";
import { useDispatch, useSelector } from "react-redux";
import COLORS from "../../../Style/colors";
import { addNewGroup, DeleteGroupByid, EditGroup } from "../../../API/fetch";
import { addGroupAction, deleteGroupAction, editGroupAction } from "../../../redux/reducers/storeReducer";

const { Title, Text } = Typography;
function AddGroup({ selectedGroup, showAddGroup, getCustomers, setShowAddGroup }) {
  const intl = useIntl();
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState()
  const { tags, cities } = useSelector((state) => state.store);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const { conditions } = values
      const data = {
        name: values.name,
        conditions: formatSelectedValues(conditions, treeData)
      }
      if (selectedGroup) {
        const response = await EditGroup(data, selectedGroup._id)
        dispatch(editGroupAction({
          id: selectedGroup._id,
          updatedGroup: response.data.group
        }))
      } else {
        const response = await addNewGroup(data)
        dispatch(addGroupAction(response.data.group))
      }
      setShowAddGroup(false)
      getCustomers()
      setLoading(false);
      openNotification({
        title: "Success",
        description: intl.formatMessage({ id: "groupUpdatedSuccess" }),
        type: "success",
        rtl: true,
      });
    } catch (error) {
      console.log(error)
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  };


  useEffect(() => {
    if (selectedGroup && showAddGroup) {
      const { conditions } = selectedGroup
      const formattedConditions = conditions.map(condition => condition.value).flat()
      form.setFieldsValue({
        name: selectedGroup.name,
        conditions: formattedConditions,
      })
    } else {
      form.setFieldsValue({
        name: "",
        conditions: [],
      })
    }
  }, [selectedGroup, showAddGroup, form])


  const treeData = [
    {
      title: intl.formatMessage({ id: "gender" }),
      value: "gender",
      key: "gender",
      children: [
        {
          title: intl.formatMessage({ id: "male" }),
          value: "male",
          key: "male",
        },
        {
          title: intl.formatMessage({ id: "female" }),
          value: "female",
          key: "female",
        },
      ],
    },
    {
      title: intl.formatMessage({ id: "city" }),
      value: "cities",
      key: "cities",
      checkable: cities.length > 0,
      children: cities.map((city) => ({
        title: city,
        value: city,
        key: city, // Set key and value as the same
      })),
    },
    {
      title: intl.formatMessage({ id: "tag1" }),
      value: "tag1",
      key: "tag1",
      checkable: tags.tags1.length > 0,
      children: tags.tags1.map((tag) => ({
        title: tag.title,
        value: tag._id,
        key: tag._id, // Set key and value as the same
      })),
    },
    {
      title: intl.formatMessage({ id: "tag2" }),
      value: "tag2",
      key: "tag2",
      checkable: tags.tags2.length > 0,
      children: tags.tags2.map((tag) => ({
        title: tag.title,
        value: tag._id,
        key: tag._id, // Set key and value as the same
      })),
    },
    {
      title: intl.formatMessage({ id: "tag3" }),
      value: "tag3",
      key: "tag3",
      checkable: tags.tags3.length > 0,
      children: tags.tags3.map((tag) => ({
        title: tag.title,
        value: tag._id,
        key: tag._id, // Set key and value as the same
      })),
    },
  ];

  const formatSelectedValues = (selectedValues) => {
    const categories = ["gender", "cities", "tag1", "tag2", "tag3"];
    return categories.map((category) => {
      if (selectedValues.includes(category)) {
        switch (category) {
          case "cities":
            return { type: category, value: cities };
          case "tag1":
            return { type: category, value: tags.tags1 };
          case "tag2":
            return { type: category, value: tags.tags2 };
          case "tag3":
            return { type: category, value: tags.tags3 };
          default:
            return null;
        }
      } else {
        // Filter values from selectedValues based on treeData structure
        const values = selectedValues.filter((val) =>
          treeData.find((item) => item.value === category)?.children?.some((child) => child.value === val)
        );
        return values.length > 0 ? { type: category, value: values } : null;
      }
    }).filter(Boolean);
  };

  const onChange = (value) => {
    setValue(value)
  }

  const { SHOW_PARENT } = TreeSelect;
  const tProps = {
    treeData,
    treeCheckable: true,
    value: value,
    onChange: onChange,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: intl.formatMessage({ id: "selectGroupCondition" }),
    style: {
      width: "100%",
    },
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await DeleteGroupByid(selectedGroup._id)
      dispatch(deleteGroupAction(selectedGroup._id))
      getCustomers()
      setLoading(false);
      setShowAddGroup(false)
      openNotification({
        title: "Success",
        description: intl.formatMessage({ id: "groupDeletedSuccess" }),
        type: "success",
        rtl: true,
      });
    } catch (error) {
      setLoading(false);
      openNotification({
        title: "Error",
        description: "something went wrong",
        type: "error",
        rtl: true,
      });
    }
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        name="add-group"
        onFinish={onFinish}
      >
        <Form.Item label={intl.formatMessage({ id: "groupeName" })} name="name" rules={[
          {
            required: true,
            message: intl.formatMessage({ id: "required" }),
          },
        ]}>
          <Input placeholder={intl.formatMessage({ id: "groupeName" })} />
        </Form.Item>
        <Divider plain>
          <Title level={5}>
            {intl.formatMessage({ id: "groupConditions" })}
          </Title>
        </Divider>
        <div style={{ paddingBottom: "10px" }}>
          <Text style={{ color: COLORS.black }} >{intl.formatMessage({ id: "groupConditionsDescription" })}</Text>
        </div>
        <Form.Item name="conditions">
          <TreeSelect {...tProps} />
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "8px", marginTop: "20px" }}
        >
          {selectedGroup ?
            <Popconfirm
              title={intl.formatMessage({ id: "deleteGroup" })}
              description={intl.formatMessage({
                id: "deleteGroupDescription",
              })}
              onConfirm={() => {
                handleDelete()
              }}
              onCancel={() => true}
              okText={intl.formatMessage({ id: "confirm" })}
              cancelText={intl.formatMessage({ id: "cancel" })}
            >
              <CustomButton
                text={intl.formatMessage({ id: "delete" })}
                type="link"
                style={{ color: COLORS.danger }}
              />
            </Popconfirm> : null}
          <CustomButton
            text={intl.formatMessage({ id: selectedGroup ? "update" : "add" })}
            type="primary"
            htmlType="submit"
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
}

export default AddGroup;
