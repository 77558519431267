import { Button, Modal } from "antd";
import React from "react";
import { useIntl } from "react-intl";

const CustomModal = ({
  title,
  btnTitle = "Open",
  confirmBtnTitle = "send",
  cancelBtnTitle = "cancel",
  handleConfirm,
  body,
  open,
  setOpen,
  showButton = true,
  showFooter = true,
}) => {
  const intl = useIntl();
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    handleConfirm();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <>
      {showButton ? (
        <Button type="primary" onClick={showModal} >
          {btnTitle}
        </Button>
      ) : null}
      <Modal
        open={open}
        title={title}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          showFooter
            ? [
              <Button key="back" onClick={handleCancel}>
                {intl.formatMessage({ id: cancelBtnTitle })}
              </Button>,
              handleConfirm ? (
                <Button key="submit" type="primary" onClick={handleOk}>
                  {intl.formatMessage({ id: confirmBtnTitle })}
                </Button>
              ) : null,
            ]
            : null
        }
      >
        {body}
      </Modal>
    </>
  );
};

export default CustomModal;
