import { Button, Drawer, Form, Input } from "antd";
import React, { useState } from "react";
import CustomButton from "../../../Components/CustomButton";
import { useIntl } from "react-intl";
import { IoFilterSharp } from "react-icons/io5";
import Filter from "./Filter";

function Search({ search, setSearch, loading, setPage }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [toggleFilter, setTogglefilter] = useState(false);

  const onFinish = async (values) => {
    setPage(1)
    setSearch({
      ...search,
      search: values.saerch,
    });

  };

  const showfilter = () => {
    setTogglefilter(true);
  };

  const onClose = () => {
    setTogglefilter(false);
    filterForm.resetFields();
  };

  const resetFilter = () => {
    setPage(1)
    form.resetFields();
    filterForm.resetFields();
    setSearch({
      search: "",
      gender: [],
      cities: [],
      groups: [],
      tags1: [],
      tag2: [],
      tag3: [],
    });

  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="search-customer"
        onFinish={onFinish}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "100%",
        }}
      >
        <Form.Item
          name="saerch"
          style={{ padding: 0, margin: 0, width: "90%" }}
        >
          <Input
            style={{ height: "40px" }}
            placeholder={intl.formatMessage({
              id: "customerSearchPlaceholder",
            })}
          />
        </Form.Item>
        <CustomButton
          text={intl.formatMessage({ id: "search" })}
          type="primary"
          htmlType="submit"
          loading={loading}
          style={{ width: "10%", height: "36px" }}
          size="medium"
        />
        <Drawer
          title={intl.formatMessage({ id: "filter" })}
          placement={"left"}
          closable={true}
          onClose={onClose}
          open={toggleFilter}
        >
          <Filter
            setSearch={setSearch}
            search={search}
            toggle={toggleFilter}
            setToggle={setTogglefilter}
            form={filterForm}
            setPage={setPage}
          />
        </Drawer>
      </Form>
      <CustomButton
        text={intl.formatMessage({ id: "reset" })}
        loading={loading}
        style={{ width: "10%", height: "36px" }}
        size="medium"
        onClick={resetFilter}
      />
      <Button
        shape="square"
        size="large"
        variant="outline"
        icon={<IoFilterSharp />}
        onClick={showfilter}
      />
    </div>
  );
}

export default Search;
