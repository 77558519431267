import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  store: null,
  events: [],
  analyticsData: [],
  // analyticsProducts: [],
  templates: [],
  campaigns: [],
  customers: [],
  groups: [],
  products: [],
  medias: [],
  cities: [],
  tags: {
    tags1: [],
    tags2: [],
    tags3: [],
  },
};

export const storeSlice = createSlice({
  name: "store",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setStore: (state, action) => {
      state.store = action.payload;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    setAnalyticsData: (state, action) => {
      state.analyticsData = action.payload;
    },
    setAnalyticProducts: (state, action) => {
      // state.analyticsProducts = action.payload;
    },
    setProducts: (state, action) => {
      state.products = action.payload;
    },
    setStoreTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setStoreCampaigns: (state, action) => {
      state.campaigns = action.payload;
    },
    setStoreCustomers: (state, action) => {
      state.customers = action.payload;
    },
    setStoreGroups: (state, action) => {
      state.groups = action.payload;
    },
    addGroup: (state, action) => {
      state.groups.push(action.payload);
    },
    deleteGroup: (state, action) => {
      state.groups = state.groups.filter(group => group._id !== action.payload);
    },
    editGroup: (state, action) => {
      const { id, updatedGroup } = action.payload;
      const index = state.groups.findIndex(group => group.id === id);
      if (index !== -1) {
        state.groups[index] = { ...state.groups[index], ...updatedGroup };
      }
    },
    setMedias: (state, action) => {
      state.medias = action.payload;
    },
    setTags: (state, action) => {
      state.tags = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
    },
  },
});

export const {
  setStore: setStoreAction,
  setEvents: setEventsAction,
  setAnalyticsData: setAnalyticsDataAction,
  setAnalyticProducts: setAnalyticProductsAction,
  setStoreTemplates: setStoreTemplatesAction,
  setStoreCampaigns: setStoreCampaignsAction,
  setStoreCustomers: setStoreCustomersAction,
  setStoreGroups: setStoreGroupsAction,
  addGroup: addGroupAction,
  deleteGroup: deleteGroupAction,
  editGroup: editGroupAction,
  setProducts: setProductsAction,
  setMedias: setMediasAction,
  setTags: setStoreTagsAction,
  setCities: setCitiesAction,
} = storeSlice.actions;

export default storeSlice.reducer;
